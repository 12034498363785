import React, { useMemo } from 'react'

import * as styles from './importModal.module.scss'
import { ProgressBar } from 'react-bootstrap'
import { ImportStatusViewProps } from './types'
import { useProfile } from '../../../hooks'
import { StaticImage } from 'gatsby-plugin-image'
import { HelpButton } from './actions'
import Svg from '../svg/svg'
import { ImportModalViews } from './importModal'

const ImportStatusView = ({
   variant,
   currentView,
   percentage,
   importError,
   importFileErrors,
   totalImported,
   helpButtonPath,
}: ImportStatusViewProps) => {
   const { profile } = useProfile()

   const heading = useMemo(() => {
      switch (currentView) {
         case ImportModalViews.IMPORTING:
            return `Importing Your ${variant}`
         case ImportModalViews.SUCCESS:
            return `${profile?.Firstname}, Your ${variant} Are Imported!`
         case ImportModalViews.ERROR:
         default:
            return 'Sorry! Import Could Not Complete.'
      }
   }, [currentView])

   const description = useMemo(() => {
      switch (currentView) {
         case ImportModalViews.IMPORTING:
            return `We have your ${variant} import file and are working behind the scenes to get everything ready for you.`
         case ImportModalViews.SUCCESS:
            return `ShareMyToolbox has finished your ${variant} import. Time to take a break and reward yourself for a job well done.`
         case ImportModalViews.ERROR:
         default:
            return 'Some errors were found during the import process. Please correct them and try again. Contact us if you need help, real humans are standing by!'
      }
   }, [currentView])

   const totalImportedMessage = useMemo(() => {
      switch (totalImported) {
         case 0:
            return `Your ${variant} Were Added`
         case 1:
            return `${totalImported} ${variant.slice(0, -1)} Added`
         default:
            return `${totalImported} ${variant} Added`
      }
   }, [totalImported])

   const content = useMemo(() => {
      switch (currentView) {
         case ImportModalViews.SUCCESS:
            return (
               <>
                  <StaticImage
                     src={'../../../assets/images/importItems/thumbs-up.png'}
                     formats={['png']}
                     alt={'Import successful'}
                  />
                  <div className={styles.importResults}>
                     <div>
                        <Svg id="items" width={30} />
                        <strong>{totalImportedMessage}</strong>
                     </div>
                  </div>
               </>
            )
         case ImportModalViews.ERROR:
            return (
               <>
                  <p>{importError}</p>
                  {importFileErrors?.length ? (
                     importFileErrors.map((error) => (
                        <p key={error.split(' ').join('-')}>{error}</p>
                     ))
                  ) : (
                     <></>
                  )}
               </>
            )
         default:
            return <></>
      }
   }, [currentView, importFileErrors, totalImported])

   const progressBarTitle = useMemo(() => {
      switch (currentView) {
         case ImportModalViews.IMPORTING:
            if (percentage <= 50) {
               return `Importing ${variant}...`
            } else if (percentage > 50 && percentage <= 75) {
               return 'Running final data cleanup and polish...'
            } else {
               return 'Almost there...'
            }
         case ImportModalViews.SUCCESS:
            return 'Import Complete'
         case ImportModalViews.ERROR:
         default:
            return 'Import Failed'
      }
   }, [currentView, percentage, variant])

   const progressBarVariant = useMemo(
      () => (currentView === ImportModalViews.ERROR ? 'danger' : 'success'),
      [currentView]
   )

   return (
      <div className={styles.leftColumn}>
         <h3 className={styles.heading}>{heading}</h3>
         <p className={styles.description}>{description}</p>
         <div
            className={
               currentView === ImportModalViews.ERROR
                  ? styles.errorContent
                  : styles.content
            }
         >
            {currentView === ImportModalViews.ERROR && (
               <HelpButton path={helpButtonPath} />
            )}
            {content}
         </div>
         <p
            className={
               currentView === ImportModalViews.ERROR
                  ? styles.progressBarErrorTitle
                  : styles.progressBarTitle
            }
         >
            {progressBarTitle}
         </p>
         <ProgressBar
            now={percentage}
            variant={progressBarVariant}
         ></ProgressBar>
         <div
            className={
               currentView === ImportModalViews.ERROR
                  ? styles.progressBarErrorLabel
                  : styles.progressBarLabel
            }
         >
            {percentage}% Complete
         </div>
      </div>
   )
}

export default ImportStatusView
