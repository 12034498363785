import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   ImageMatchPayload,
   UploadMatchedImagesPayload,
   UseImageMatch,
} from './types'

export default function (): UseImageMatch {
   const request = useAPI()

   const {
      data,
      error,
      isError: isImageMatchError,
      isLoading: isImageMatchLoading,
      isSuccess: isImageMatchSuccess,
      mutate,
   } = useMutation(getImagesFromAPI)

   async function getImagesFromAPI(
      payload: ImageMatchPayload
   ): Promise<AxiosResponse> {
      return request.post(PATH.IMAGES.IMAGE_MATCH, payload)
   }

   const imageMatch = async (options: ImageMatchPayload) =>
      await mutate(options)

   const {
      error: uploadImagesError,
      isError: isUploadImagesError,
      isLoading: isUploadImagesLoading,
      isSuccess: isUploadImagesSuccess,
      mutate: uploadMutate,
   } = useMutation(addMatchedImagesToItem)

   async function addMatchedImagesToItem(
      payload: UploadMatchedImagesPayload
   ): Promise<AxiosResponse> {
      return request.post(PATH.IMAGES.SINGLE_ITEM_UPLOAD, payload)
   }

   const uploadMatchedImages = async (payload: UploadMatchedImagesPayload) => {
      await uploadMutate(payload)
   }

   return {
      // @ts-ignore
      imageMatchError: error?.response?.data?.Message as string,
      imageMatchData: data?.data,
      imageMatch,
      isImageMatchError,
      isImageMatchLoading,
      isImageMatchSuccess,
      uploadMatchedImages,
      isUploadImagesLoading,
      isUploadImagesSuccess,
      isUploadImagesError,
      // @ts-ignore
      uploadImagesError: uploadImagesError?.response?.data?.Message as string,
   }
}
