export const GLOBAL = {
   DESKTOP_WIDTH: 768,
}

export const API_URL = process.env.API_BASE_URL

export const PATH = {
   AUTH: {
      FORGOTTEN_PASSWORD: '/account/request-reset-password',
      LOGIN: '/account/login',
      LOGOUT: '/account/logout',
      RESET_PASSWORD: '/account/set-password',
      REFRESH_TOKEN: '/account/token-refresh',
   },
   MANUFACTURERS: {
      FILTER: '/setting/manufacturers/list',
      LIST: '/setting/manufacturers/list-with-count',
      ADD: '/setting/manufacturers/add',
      UPDATE: '/setting/manufacturers/Update',
      DELETE: '/setting/manufacturers/delete?manufacturerId=',
   },
   CATEGORIES: {
      LIST: '/setting/categories/list',
      ADD: '/setting/category/add',
      UPDATE: '/setting/category/Update',
      DELETE: '/setting/category/delete?categoryId=',
   },
   NOTETYPES: {
      LIST: '/note/get-note-types',
      ADD: '/note/create-note-type',
      UPDATE: '/note/update-note-type',
      DELETE: '/note/delete-note-type',
   },
   SETTINGS: {
      LIST: '/account/settings',
      UPDATE_SYSTEM: '/account/settings/system/update',
      UPDATE_ITEM_RECORD: '/account/settings/item-record/update',
      UPDATE_NOTIFICATIONS: '/account/settings/notifications/update',
   },
   ITEM_HISTORY: {
      LIST_STATUS: '/history/item-history-status',
      LIST: '/history/item-history',
      DOWNLOAD: '/history/download-item-history',
   },
   AUDIT_HISTORY: {
      LIST_STATUS: '/history/audit-history-status',
      LIST_CONDITIONS: '/history/audit-history-conditions',
      HISTORY_CONDITIONS: '/history/audit-history-conditions',
      LIST: '/history/audit-history',
      DOWNLOAD: '/history/download-audit-history',
   },
   COSTING_HISTORY: {
      LIST_STATUS: '/history/costing-history-status',
      HISTORY_CONDITIONS: '/history/costing-history-conditions',
      LIST: '/history/costing-history',
      DOWNLOAD: '/history/download-costing-history',
   },
   NOTE_HISTORY: {
      LIST_STATUS: '',
      HISTORY_CONDITIONS: '',
      LIST: '',
      DOWNLOAD: '',
   },
   ITEMS: {
      LIST: '/item/search-tool-items',
      LIST_VIEW: '/item/view',
      COUNT: '/item/items-count',
      ADD: '/',
      UPDATE: '/item/update',
      DELETE: '/item/delete/',
      DOWNLOAD: '/item/download',
      CREATE: '/item/create',
      BARCODE_LOOKUP: '/item/is-barcode-valid',
      UPLOAD_PICTURE: '/item/upload-picture',
      CHANGE_POSITION: '/item/set-tool-picture-position',
      DELETE_IMAGE: '/item/delete-picture',
      REMOVE_FROM_KIT: '/item/remove-from-kit',
      ADD_TO_KIT: '/item/add-to-kit',
      GET_KITS: '/item/get-kits-for-add-to-kit',
      GET_ITEMS_FOR_KIT: '/item/get-associated-items-for-kit',
      GET_ITEMS_FOR_ADD_TO_KIT: '/item/get-Items-for-add-to-kit',
      IMPORT_ITEMS: '/item/import',
      SET_AVAILABILITY: '/item/set-availability',
   },
   ITEMS_NOTE: {
      LIST: '/note/get-for-item',
   },
   ITEMS_HISTORY: {
      LIST: '/item/history',
   },
   ITEM_STATUSES: {
      LIST: '/history/item-history-status ',
   },
   ITEM_TYPES: {
      LIST: '/item/types',
   },
   ITEM_GPS_LOCATIONS: {
      LIST: '/item/get-gps-logs?toolId=',
   },
   PROFILE: {
      GET: '/account/profile',
      RESET_PASSWORD: '/account/password/update',
      UPDATE_SETTINGS: '/account/update-account-settings',
      UPDATE_DETAILS: '/account/profile/update',
   },
   INDUSTRIES: {
      GET: '/account/industries',
   },
   USERS: {
      GET: '/users/details',
      UPDATE: '/users/update-details',
      LIST: '/users/list',
      PASSWORD_GENERATE: '/users/password-generate',
   },
   USER_ITEMS: {
      LIST: '/item/all-active-items-list',
   },
   CONNECTIONS_FILTER: {
      LIST: '/connection/list?includeActiveFilterOption=True',
   },
   REGISTER: {
      CHECK_EMAIL: '/registration/is-email-registered',
      UPGRADE_ACCOUNT: '/registration/free-to-trial',
      TRIAL_ACCOUNT: '/registration/new-trial',
      ACCOUNT_VERIFY: '/registration/account-verify',
   },
   LOCATIONS: {
      LIST: '/location/list-with-count',
      LIST_ACTIVE: '/location/list',
      LIST_WAREHOUSE: '/location/list?warehouseOnly=true',
      ADD: '/location/add-new-location',
      UPDATE: '/location/update-location',
      DELETE: '/location/delete?LocationId=',
      IMPORT: '/location/import',
   },
   NOTES: {
      LIST: '/note/get-notes-with-count',
      ADD: '/note/create-Item-note',
      UPDATE: '/note/update-Item-note',
      DELETE: '/note/delete-Item-note',
      DOWNLOAD: '/note/download-notes',
      DETAILS: '/note/get-details',
   },
   CONNECTIONS: {
      LIST: '/connection/get-connections-with-count',
      ADD: '/connection/add',
      UPDATE: '/connection/Update',
      DELETE: '/connection/delete',
      DOWNLOAD: '/connection/download',
      MASS_TRANSFER: '/connection/connected-employee-and-virtual?requestType=',
      IMPORT: '/connection/import-connections',
   },
   CONDITIONS: {
      LIST: '/history/audit-history-conditions',
   },
   NOTE_TYPES: {
      LIST: '/note/get-note-types',
   },
   DASHBOARD: {
      ITEM_COUNT: '/items/count',
      NOTIFICATION_TOTALS: '/notifications/count',
      NOTIFICATIONS: '/notifications/list',
      NOTIFICATION_DISMISS: '/notifications/update',
   },
   MASS_TRANSFER: {
      CONNECTION: '/mass-transfer/connection',
      LOCATION: '/mass-transfer/location',
   },
   AUDITS: {
      CREATE: '/audit/create-or-update',
      LIST: '/audit/list',
      DOWNLOAD: '/audit/list/download',
      DOWNLOAD_AUDIT_ITEMS: '/history/download-audit-history',
      DELETE: '/audit/delete?auditID=',
      DETAILS: '/audit/details',
      UNCONFIRMED_ITEMS: '/audit/get-unconfirmed-items',
      CONFIRMED_ITEMS: '/audit/get-confirmed-items',
      FOUND_ITEMS: '/audit/get-not-found-Items', // not found items are actually items found but not scanned
      DRAFT_DETAILS: '/audit/draft-details',
      DRAFT_ITEMS: '/audit/draft-items',
      MARK_AUDIT_AS_REVIEWED: '/audit/marked-as-reviewed?AuditID=',
   },
   AUDITORS: {
      LIST: '/audit/get-auditors-list',
      VALIDATE: '/audit/is-valid audited-by-user',
   },
   WORKFLOW: {
      LOAN_BORROW: '/work-flow/initiate-loan-borrow-request',
      RETURN: '/work-flow/initiate-return-request',
      WAREHOUSE_TRANSFER: '/work-flow/initiate-warehouse-transfer',
      FIELD_TRANSFER: '/work-flow/initiate-field-transfer',
   },
   SETTINGS_WIZARD: {
      SET_SETTINGS: '/account/wizard',
   },
   GOOGLE: {
      VALIDATE_RECAPTCHA_TOKEN: '/google/recaptcha-verify-token',
   },
   ATTACHMENTS: {
      ATTACHMENT_UPLOAD: '/attachment/upload',
      ATTACHMENT_REMOVE: '/attachment/remove',
   },
   TABLE: {
      SORT_COLUMN_OPTIONS: '/item/get-sort-column-options',
   },
   IMAGES: {
      IMAGE_MATCH: '/ai-image/get-ai-images',
      SINGLE_ITEM_UPLOAD: '/ai-image/upload-images-for-single-item',
   },
}

export const API_KEY = process.env.API_KEY

// breakpoints for the app based on min-width
export const BREAKPOINTS = {
   mobile: 0,
   tablet: 768,
   desktop: 1280,
}

export function formatPrice(str) {
   let price = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: parseFloat(str) % 1 === 0 ? 0 : 2,
   }).format(str)
   if (isNaN(parseInt(str)) || parseInt(str) <= 0) {
      return '-'
   }
   price = parseFloat(str) % 1 === 0 ? `${price}.00` : price
   return `${price ? price : '_'}`
}
