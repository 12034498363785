import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from '../common'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { ProgressBar } from '../common/progressBar'
import { ImageMatchModalProps } from './types'
import * as styles from './reusableModals.module.scss'
import { ProgressBarVariant } from '../common/progressBar/types'
import { useProfile } from '../../hooks'

const ImageMatchModal = ({
   isOpen,
   handleClose,
   isSuccess,
   errorMessage,
}: ImageMatchModalProps) => {
   const { profile } = useProfile()
   const [percentage, setPercentage] = useState<number>(0)

   useEffect(() => {
      if (isOpen) {
         const intervalId = setInterval(() => {
            setPercentage((prevCount) => prevCount + 1)
         }, 200)

         // stop progress bar at 98 in case request has not completed
         if (percentage === 98) {
            return clearInterval(intervalId)
         }

         if (isSuccess || errorMessage) {
            setPercentage(100)
            return clearInterval(intervalId)
         }

         return () => clearInterval(intervalId)
      } else {
         setPercentage(0)
      }
   }, [isOpen, percentage])

   const heading = useMemo(() => {
      if (errorMessage) {
         return 'Sorry! Image Match Could Not Complete.'
      } else if (isSuccess) {
         return `${
            profile?.Firstname ? profile.Firstname + ', ' : ''
         }Your Image Match is Complete`
      } else {
         return 'Performing Image Match'
      }
   }, [isSuccess, errorMessage])

   const progressBarTextAbove = useMemo(() => {
      if (errorMessage) {
         return errorMessage
      } else if (isSuccess) {
         return 'Match Complete'
      } else {
         return 'Sending the robots to work...'
      }
   }, [isSuccess, errorMessage])

   const progressBarVariant: ProgressBarVariant = useMemo(() => {
      return errorMessage ? 'danger' : 'success'
   }, [errorMessage])

   return (
      <Modal
         isModalVisible={isOpen}
         closeModal={handleClose}
         title="AI Image Match"
         maxWidth="713px"
      >
         <div className={styles.imageMatchContent}>
            <Row>
               <Col xs={12}>
                  <h1>{heading}</h1>
                  <div className={styles.imageMatchImage}>
                     {isSuccess ? (
                        <StaticImage
                           src="../../../assets/images/importItems/thumbs-up.png"
                           formats={['png']}
                           alt="Thumbs up"
                        />
                     ) : (
                        <StaticImage
                           src="../../../assets/images/importItems/ai-icon-large.png"
                           formats={['png']}
                           alt="Match in progress"
                        />
                     )}
                  </div>
                  <ProgressBar
                     textAbove={progressBarTextAbove}
                     percentage={percentage}
                     variant={progressBarVariant}
                  />
                  <Button
                     variant={
                        isSuccess || errorMessage ? 'primary' : 'tertiary'
                     }
                     onClick={handleClose}
                     className={styles.imageMatchCancelButton}
                  >
                     {isSuccess || errorMessage ? 'Close' : 'Cancel'}
                  </Button>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}

export default ImageMatchModal
