import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseItemUpload, ItemUploadResponse } from './useItemUpload.type'

export default function (): UseItemUpload {
   const request = useAPI()
   const [init, setInit] = useState<boolean>(false)
   const { data, isLoading, refetch } = useQuery('images', getImageFromAPI)
   const {
      error,
      isLoading: isImporting,
      isSuccess: isImportSuccess,
      mutate: imageMutate,
   } = useMutation(importImageFile)

   const {
      error: isPositionError,
      isLoading: isPositionLoading,
      isSuccess: isPositionSuccess,
      mutate: positionMutate,
   } = useMutation(changeImagePosition)

   const {
      error: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      mutate: deleteMutate,
   } = useMutation(deleteImageByPosition)

   async function getImageFromAPI(): Promise<
      AxiosResponse<ItemUploadResponse>
   > {
      if (init) {
         try {
            return await request.post(PATH.ITEMS.UPLOAD_PICTURE)
         } catch (error) {
            // TODO: handle errors better
            console.log(error)
         }
      }
   }

   async function importImageFile(file: File): Promise<AxiosResponse<any>> {
      return await request.post(PATH.ITEMS.UPLOAD_PICTURE, file, {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      })
   }

   async function changeImagePosition(obj: {}): Promise<AxiosResponse<any>> {
      return await request.post(PATH.ITEMS.CHANGE_POSITION, obj)
   }

   async function deleteImageByPosition(obj: {}): Promise<AxiosResponse<any>> {
      return await request.post(PATH.ITEMS.DELETE_IMAGE, obj)
   }

   const deleteImage = async (obj) => {
      setInit(true)
      await deleteMutate(obj)
   }

   const upload = async (file: File) => {
      setInit(true)
      await imageMutate(file)
   }

   const changePosition = async (obj) => {
      setInit(true)
      await positionMutate(obj)
   }

   return {
      // @ts-ignore
      error: error?.response?.data?.Message as string,
      upload,
      deleteImage,
      changePosition,
      isImporting,
      isImportSuccess,
      isLoading,
      isPositionError,
      isPositionLoading,
      isPositionSuccess,
      isDeleteError,
      isDeleteLoading,
      isDeleteSuccess,
   }
}
